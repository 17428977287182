:root {
  --common-color: #d4af37;
  --common-color-hover: #e1c16e;
}

.footer {
  background-color: #f8f9fa;
  color: #343a40;
  padding: 20px 0;
  border-top: 3px solid var(--common-color);
}

.footer p {
  margin: 5px 0;
}

.footer .footer-link {
  color: #343a40;
  font-weight: bold;
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.footer .footer-link:hover {
  color: var(--common-color);
  text-decoration: underline;
}

.btn-link {
  background: none;
  border: none;
  color: #343a40;
  cursor: pointer;
  padding: 0;
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.btn-link:hover {
  color: var(--common-color);
  text-decoration: underline;
}

.modal-header,
.modal-footer {
  border: none;
}

.modal-title {
  font-weight: bold;
}

.modal-body p {
  color: #343a40;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 20px; /* Added padding for better alignment on larger screens */
}

.footer-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px; /* Added margin for spacing */
}

.footer-contact {
  text-align: center;
  margin-top: 10px; /* Added margin for spacing */
}

.footer-contact p {
  margin: 0;
  padding: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center; /* Center align items on smaller screens */
    padding: 0 10px; /* Adjust padding for smaller screens */
  }

  .footer-links {
    margin-bottom: 10px; /* Increased margin to separate elements */
  }

  .footer-contact {
    margin-top: 15px; /* Added margin for better spacing */
  }

  .footer .footer-link,
  .btn-link {
    font-size: 0.9rem; /* Slightly smaller font size for readability on smaller screens */
  }
}

@media (max-width: 576px) {
  .footer-container {
    padding: 0 5px; /* Further adjust padding for extra small screens */
  }

  .footer .footer-link,
  .btn-link {
    font-size: 0.85rem; /* Further reduced font size for extra small screens */
  }

  .footer-contact p {
    font-size: 0.85rem; /* Adjusted font size for extra small screens */
  }
}
