/* Define CSS variables for the theme */
:root {
  --common-color: #d4af37; /* Metallic gold */
  --common-color-hover: #e1c16e; /* Hover color for common elements */
  --text-color: #000000; /* Default text color */
  --background-color: #f8f9fa; /* Background color for light backgrounds */
  --border-color: #dee2e6; /* Border color */
  --font-family: Arial; /* Font family */
  --active-text-color: #000000; /* Active text color */
  --dropdown-background: #ffffff; /* Dropdown background color */
}

/* General body and text styles */
body {
  background-color: var(--background-color);
  font-family: var(--font-family);
  color: var(--text-color);
  font-size: 18px; /* Increased base font size */
}

.main {
  padding: 20px;
  min-height: 80vh;
}

/* Card styles */
.card {
  margin-top: 20px;
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 2rem;
}

/* Alert styles */
.alert {
  margin-top: 20px;
}

/* Header styles */
h3 {
  padding: 1rem 0;
  font-size: 1.5rem; /* Increased font size */
}

/* Rating styles */
.rating span {
  margin: 0.1rem;
}

/* Form styles */
.form-group {
  margin-bottom: 1.5rem;
}

.form-control {
  border-radius: 10px;
  padding: 0.75rem;
  font-size: 1rem; /* Increased font size */
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--common-color); /* Metallic gold */
}

/* Button styles */
.btn-primary,
.btn-common {
  background-color: var(--common-color); /* Metallic gold */
  border-color: var (--common-color); /* Metallic gold */
  border-radius: 10px;
  padding: 0.75rem;
  font-size: 1rem; /* Increased font size */
}

.btn-primary:hover,
.btn-common:hover {
  background-color: var(--common-color-hover); /* Darker shade of metallic gold */
  border-color: var(--common-color-hover); /* Darker shade of metallic gold */
}

/* Navbar link styles */
.nav-link-common {
  border-radius: 8px;
  border-color: var(--border-color);
  color: var(--text-color);
  font-weight: bold;
  padding: 10px 20px;
  font-size: 1rem; /* Increased font size */
  margin: 0 5px;
  transition: color 0.3s ease, background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.nav-link-common.active {
  background-image: linear-gradient(135deg, var(--common-color) 0%, var(--common-color-hover) 100%);
  color: white;
  border-color: var(--common-color);
}

.nav-link-common:hover,
.nav-link-common.active {
  color: var(--active-text-color) !important;
  background-color: var(--common-color-hover) !important;
  border-radius: 5px;
}

.nav-text {
  margin-left: 0.5rem;
  color: var(--text-color) !important;
  font-size: 1rem; /* Increased font size */
}

.nav-text-bold {
  font-weight: bold;
  color: var(--text-color) !important;
  font-size: 1rem; /* Increased font size */
}

.navbar-toggler {
  border-color: var(--common-color-hover);
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255,255,255,1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.nav-dropdown .dropdown-toggle {
  color: var(--text-color) !important;
}

.dropdown-menu {
  background-color: var(--dropdown-background) !important;
}

.dropdown-item {
  color: var(--text-color) !important; /* Change text color for visibility */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: var(--common-color-hover) !important;
  color: var(--active-text-color) !important;
}

/* Header specific styles */
.header-navbar {
  background-color: var(--common-color) !important; /* Metallic color for header */
  color: var(--text-color) !important;
}

.nav-link-common {
  color: var(--text-color) !important;
  transition: color 0.3s ease, background-color 0.3s ease;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-family: var(--font-family);
  font-size: 1rem; /* Increased font size */
}

.nav-link-common:hover,
.nav-link-common.active {
  color: var(--active-text-color) !important;
  background-color: var(--common-color-hover) !important;
  border-radius: 5px;
}

.nav-text {
  margin-left: 0.5rem;
  color: var (--text-color) !important;
}

.nav-text-bold {
  font-weight: bold;
  color: var(--text-color) !important;
}

.navbar-toggler {
  border-color: var(--common-color-hover);
}

.nav-dropdown .dropdown-toggle {
  color: var(--text-color) !important;
}

.dropdown-menu {
  background-color: var(--dropdown-background) !important;
}

.dropdown-item {
  color: var(--text-color) !important; /* Change text color for visibility */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: var(--common-color-hover) !important;
  color: var(--active-text-color) !important;
}

/* Card common styles */
.card-common {
  border: none;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 15px;
}

.card-title-common {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.25rem; /* Increased font size */
  color: var(--text-color); /* Ensure text color is visible */
}

/* Rating stars common styles */
.rating-stars-common {
  font-size: 1.25rem; /* Increased font size */
  margin-left: 10px;
  color: var(--text-color); /* Ensure text color is visible */
}

/* Subscription card styles */
.card-subscription {
  cursor: pointer;
  background-color: var(--background-color);
  border-color: #ced4da;
  color: #000000;
  box-shadow: none;
  transition: transform 0.2s;
}

.card-subscription.selected {
  background-color: var(--common-color);
  border-color: var(--common-color);
  color: var(--text-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: scale(1.02);
}

.card-subscription.selected h5, .card-subscription.selected h4 {
  color: var(--text-color);
  font-size: 1.25rem; /* Increased font size */
}

/* Responsive navbar styles */
@media (max-width: 992px) {
  .nav-item-hide {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .nav-item-show {
    display: none !important;
  }
}


